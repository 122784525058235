<template>
  <v-form @submit.prevent="submitPage">
    <v-container class="formPage py-8">
      <v-row>
        <v-col cols="12">
          <h2 class="text-h4">
            {{ isModeNew ? $locale.addArticle : $locale.editArticle }}
          </h2>
        </v-col>
        <v-col cols="12" sm="8" md="9">
          <v-text-field
            :label="$locale.inputArticleName"
            outlined
            hide-details="auto"
            v-model="article.title"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-dialog
            ref="dialog"
            v-model="datePopup"
            :return-value.sync="article.published_at"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="$dayjs(article.published_at).format('DD MMM YYYY')"
                :placeholder="$locale.inputArticleDate"
                outlined
                readonly
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
              />
            </template>
            <v-date-picker v-model="article.published_at" scrollable no-title>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datePopup = false">
                {{ $locale.cancel }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(article.published_at)"
              >
                {{ $locale.select }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="8" md="9">
          <v-textarea
            :label="$locale.inputArticlePreview"
            outlined
            hide-details="auto"
            class="formPage_preview"
            v-model="article.preview"
            no-resize
            rows="0"
            height="100%"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-card flat class="formPage_image">
            <button
              type="button"
              class="formPage_image_btn py-4"
              @click="selectImage"
            >
              <v-icon class="mb-2">mdi-image-multiple-outline</v-icon>
              <v-label>{{ $locale.inputArticleImage }}</v-label>
            </button>
            <v-img
              :src="article.image"
              v-if="article.image"
              class="formPage_image_preview"
            >
              <div class="formPage_image_preview_actions pa-2">
                <v-btn fab x-small class="mr-2" @click="selectImage">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn fab x-small @click="resetImage">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="12">
          <ckeditor
            v-if="isPageLoaded"
            :editor="editor"
            v-model="article.content"
            :config="editorConfig"
          ></ckeditor>
        </v-col>
        <v-col cols="12" class="mb-8 mt-4">
          <v-switch
            v-model="article.is_pinned"
            inset
            :label="$locale.inputArticlePinned"
            hide-details="auto"
            class="mt-0 pt-sm-0"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            x-large
            depressed
            color="primary"
            type="submit"
            :loading="isBeingSaved"
          >
            {{ isModeNew ? $locale.add : $locale.saveChanges }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import '@/assets/styles/ckeditor.scss';
import {
  GET_ARTICLE,
  ADD_ARTICLE,
  EDIT_ARTICLE,
  CLEAR_ARTICLE_STATE,
} from '@/store/news/types';
import { TOGGLE_PAGE_LOADER } from '@/store/app/types';

export default {
  name: 'PopupFormArticle',
  metaInfo() {
    const title = this.isModeNew
      ? this.$locale.addArticle
      : this.$locale.editArticle;
    return {
      title,
    };
  },
  data: () => ({
    editor: Editor,
    article: {
      title: '',
      preview: '',
      content: '',
      published_at: '',
      is_pinned: false,
      image: '',
    },
    isBeingSaved: false,
    datePopup: false,
    snack: false,
    snackColor: '',
    snackText: '',
    isPageLoaded: false,
  }),
  props: {
    id: {
      type: [String, Number],
    },
  },
  created() {
    this.init();
    this.article.published_at = this.$dayjs(new Date()).format('YYYY-MM-DD');
  },
  methods: {
    async init() {
      this.clearPageState();

      if (!this.isModeNew) {
        await this.getPage();
      }

      this.$store.dispatch(TOGGLE_PAGE_LOADER, false);
      this.isPageLoaded = true;
    },
    async clearPageState() {
      this.$store.dispatch(CLEAR_ARTICLE_STATE);
    },
    async getPage() {
      this.$store.dispatch(TOGGLE_PAGE_LOADER, true);
      await this.$store.dispatch(GET_ARTICLE, this.id);

      this.article.title = this.articleData.title;
      this.article.preview = this.articleData.preview;
      this.article.image = this.articleData.image;
      this.article.content = this.articleData.content;
      this.article.published_at = this.articleData.published_at;
      this.article.is_pinned = this.articleData.is_pinned;
    },
    async submitPage() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return false;
      // }

      this.isBeingSaved = true;

      const article = { ...this.article };

      if (this.isModeNew) {
        try {
          await this.$store.dispatch(ADD_ARTICLE, article);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successArticleAdd;

          this.article.title = '';
          this.article.content = '';
          this.article.preview = '';
          this.article.published_at = this.$dayjs(new Date()).format(
            'YYYY-MM-DD'
          );
          this.article.is_pinned = false;
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      } else {
        try {
          article.id = this.id;
          await this.$store.dispatch(EDIT_ARTICLE, article);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successChangesSaved;
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      }

      this.isBeingSaved = false;
      window.scrollTo(0, 0);
    },
    selectImage() {
      const vm = this;
      window.CKFinder.modal({
        chooseFiles: true,
        width: 800,
        height: 600,
        onInit: function (finder) {
          finder.on('files:choose', function (evt) {
            var file = evt.data.files.first();
            vm.article.image = file.getUrl();
          });
        },
      });
    },
    resetImage() {
      this.article.image = null;
    },
  },
  computed: {
    articleData() {
      return this.$store.getters.article;
    },
    isModeNew() {
      return this.$route.name === 'AddArticle';
    },
    editorConfig() {
      return {
        language: 'uk',
        placeholder: this.$locale.inputArticleText,
        toolbar: {
          items: [
            'bold',
            'italic',
            'underline',
            '|',
            'alignment',
            '|',
            'fontSize',
            'fontColor',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'link',
            'insertImage',
            'mediaEmbed',
            'CKFinder',
            'blockQuote',
            'insertTable',
            'horizontalLine',
            '|',
            'undo',
            'redo',
          ],
        },
        image: {
          toolbar: [
            'toggleImageCaption',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
          ],
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            // "tableCellProperties",
            // "tableProperties",
          ],
        },
        link: {
          addTargetToExternalLinks: true,
        },
        ckfinder: {
          uploadUrl: process.env.VUE_APP_CKFINDER_UPLOAD_URL,
        },
        mediaEmbed: {
          previewsInData: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.formPage {
  max-width: 900px;
}
.formPage_image {
  position: relative;
  padding-bottom: 56.25%;
}
.formPage_image_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 0.125em dashed var(--v-subtle-darken1);
  width: 100%;
  position: absolute;
  border-radius: 4px;
}
.formPage_image_preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.formPage_image_preview_actions {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep {
  .formPage_preview {
    height: 100%;

    .v-input__control {
      height: 100%;
    }
    textarea {
      min-height: 5.75em;
    }
  }
}
</style>
